import React, { useState, useEffect, useCallback, useMemo } from 'react'; // Added useMemo
import { ThemeProvider, createGlobalStyle, styled } from 'styled-components';
import useTheme from './hooks/useTheme';
import useGlobalSearchFocus from './hooks/useGlobalSearchFocus'; // Import the new hook
import { fontFamilies, fontSizes, fontWeights, lineHeights, letterSpacings } from './styles/typography';
import { space, sizes, shadows, radii, zIndices } from './styles/tokens';
import { breakpoints, mediaQueries } from './styles/breakpoints';
import { getCurrentSiteConfig } from './config/sites';

// Components (NavMenu, SearchInput, ColorPalette are used within Footer)
import IFrame from './components/Layout/IFrame';
import Footer from './components/Layout/Footer';

// Global styles
const GlobalStyle = createGlobalStyle`
  :root {
    --primary-color: ${({ theme }) => theme.colors.primary};
    --secondary-color: ${({ theme }) => theme.colors.secondary};
    --accent-color: ${({ theme }) => theme.colors.accent};
    --success-color: ${({ theme }) => theme.colors.success};
    --warning-color: ${({ theme }) => theme.colors.warning};
    --error-color: ${({ theme }) => theme.colors.error};
    --info-color: ${({ theme }) => theme.colors.info};
    
    --primary-hover: ${({ theme }) => theme.colors.primaryHover};
    --secondary-hover: ${({ theme }) => theme.colors.secondaryHover};
    --primary-active: ${({ theme }) => theme.colors.primaryActive};
    --secondary-active: ${({ theme }) => theme.colors.secondaryActive};
    
    /* Extract RGB values for use with alpha */
    --accent-color-rgb: ${({ theme }) => {
      const hex = theme.colors.accent.replace('#', '');
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);
      return `${r}, ${g}, ${b}`;
    }};
  }

  html {
    height: 100%;
  }

  body {
    margin: 0;
    padding: 0;
    height: 100%; /* Ensure body takes full height */
    display: flex; /* Use flexbox for layout */
    flex-direction: column; /* Stack children vertically */
    font-family: ${({ theme }) => theme.fontFamilies.mono};
    background-color: var(--primary-color); /* Apply primary color to body */
    overflow: hidden; /* Prevent body scroll */

    /* Mobile optimization - prevent overscroll behavior */
    overscroll-behavior: none;
    
    /* Mobile optimization - prevent text size adjustment */
    -webkit-text-size-adjust: 100%;
    
    /* Mobile optimization - improve touch scrolling */
    -webkit-overflow-scrolling: touch;
  }

  #root {
    flex-grow: 1; /* Allow root to grow and fill space */
    display: flex;
    flex-direction: column;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  /* Mobile optimization - remove tap highlight on mobile */
  input,
  textarea,
  button,
  select,
  a,
  div {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  
  /* Mobile optimization - fix 100vh issue on mobile browsers */
  @supports (-webkit-touch-callout: none) {
    .vh-fix {
      height: -webkit-fill-available;
    }
  }
`;

// Main content area that holds the iframe
const MainContent = styled.main`
  flex-grow: 1; /* Allow main content to fill available space */
  overflow: hidden; /* Prevent scrolling within this container */
  position: relative; /* Needed for iframe absolute positioning if used */
`;

// URL detection regex (defined outside component for stability)
const urlRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?(\/.+)?$/i;

function App() {
  const siteConfig = getCurrentSiteConfig();
  const { selectedPalette, applyColorPalette, getThemeColors } = useTheme();
  const [iframeSrc, setIframeSrc] = useState(null); // State for iframe source URL
  const [isNavMenuOpen, setIsNavMenuOpen] = useState(false); // State for NavMenu visibility

  // Hook to handle global keyboard listener for search focus
  useGlobalSearchFocus(); // Use the custom hook

  // Function to toggle NavMenu visibility
  const toggleNavMenu = useCallback(() => {
    setIsNavMenuOpen(prev => !prev);
  }, []);

  // Set document title based on site config
  useEffect(() => {
    document.title = siteConfig.title;
  }, [siteConfig.title]);

  // Handle search: Open URLs/external searches in new tab, app links in iframe
  const handleSearch = useCallback((searchTerm) => {
    if (!searchTerm) {
      return;
    }

    // Check if the input is a URL
    if (urlRegex.test(searchTerm)) {
      // Ensure the URL starts with http:// or https://
      const formattedUrl = searchTerm.startsWith('http://') || searchTerm.startsWith('https://')
        ? searchTerm
        : `https://${searchTerm}`;

      window.open(formattedUrl, '_blank');
      return;
    }

    // Flag to track if a nav link match was found
    let matchFound = false;

    // Search through nav links
    for (const link of siteConfig.navLinks) { // Use const
      const linkText = link.label.toLowerCase();
      // Re-added length check to prevent short terms matching long labels
      if (searchTerm.length >= 3 && linkText.includes(searchTerm.toLowerCase())) {
        // Open matching app link in iframe
        setIframeSrc(link.url);
        matchFound = true;
        // Close NavMenu if open after selection (optional, good UX)
        setIsNavMenuOpen(false);
        break;
      }
    }

    // If no nav link match, fall back to search.ffive.org
    if (!matchFound && searchTerm) {
      window.open(`https://search.ffive.org/search?q=${encodeURIComponent(searchTerm)}`, '_blank');
    }
  }, [siteConfig.navLinks]); // Removed urlRegex from dependency array

  // Get current theme colors
  const colors = getThemeColors();

  // Memoize the theme object to prevent unnecessary re-renders
  const theme = useMemo(() => {
    // Calculate hover and active states only when colors change
    const primaryHover = colors.primary + '11';
    const secondaryHover = colors.secondary + '11';
    const primaryActive = colors.primary + '22';
    const secondaryActive = colors.secondary + '22';

    return {
      colors: {
        ...colors,
        primaryHover,
        secondaryHover,
        primaryActive,
        secondaryActive,
      },
      fontFamilies,
      fontSizes,
      fontWeights,
      lineHeights,
      letterSpacings,
      space,
      sizes,
      shadows,
      radii,
      zIndices,
      mediaQueries,
      breakpoints,
    };
  }, [colors]); // Dependency: only recalculate theme when colors object changes

  // Keyboard listener logic is now handled by useGlobalSearchFocus hook

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <MainContent>
        {/* IFrame receives theme via ThemeProvider context */}
        <IFrame src={iframeSrc} />
      </MainContent>
      {/* Render the actual Footer component */}
      <Footer
        links={siteConfig.navLinks}
        onSearch={handleSearch}
        selectedPalette={selectedPalette}
        onSelectPalette={applyColorPalette}
        isNavMenuOpen={isNavMenuOpen}
        toggleNavMenu={toggleNavMenu}
        setIframeSrc={setIframeSrc}
      />
    </ThemeProvider>
  );
}

export default App;
