// Site variants configuration
export const SITE_VARIANTS = {
  PUBLIC: 'public',
  PRIVATE: 'private'
};

export const siteConfigs = {
  [SITE_VARIANTS.PUBLIC]: {
    title: 'ffive.org',
    domain: 'ffive.org',
    requiresAuth: false,
    navLinks: [
      { label: 'CYBERCHEF', url: 'https://cyber.ffive.org', external: true },
      { label: 'PASTE', url: 'https://paste.ffive.org', external: true },
      { label: 'SCRIBBLE', url: 'https://scribble.ffive.org', external: true },
      { label: 'SEARCH', url: 'https://search.ffive.org', external: true },
      { label: 'TOOLS', url: 'https://tools.ffive.org', external: true },
      { label: 'TRANSLATE', url: 'https://translate.ffive.org', external: true },
      { label: 'LOGIN', url: 'https://ffive.ffive.org', external: true }
    ]
  },
  [SITE_VARIANTS.PRIVATE]: {
    title: 'ffive.ffive.org',
    domain: 'ffive.ffive.org',
    requiresAuth: true,
    navLinks: [
      { label: 'AUTH', url: 'https://auth.ffive.org', external: true },
      { label: 'CYBERCHEF', url: 'https://cyber.ffive.org', external: true },
      { label: 'FAST', url: 'https://fast.ffive.org', external: true },
      { label: 'GIT', url: 'https://git.ffive.org', external: true },
      { label: 'NPM', url: 'https://npm.ffive.org', external: true },
      { label: 'PASTE', url: 'https://paste.ffive.org', external: true },
      { label: 'SCRIBBLE', url: 'https://scribble.ffive.org', external: true },
      { label: 'SEARCH', url: 'https://search.ffive.org', external: true },
      { label: 'TOOLS', url: 'https://tools.ffive.org', external: true },
      { label: 'TRANSLATE', url: 'https://translate.ffive.org', external: true }
    ]
  }
};

// Determine which site variant to use based on hostname
export const getSiteVariant = () => {
  const hostname = window.location.hostname;
  if (hostname === 'ffive.ffive.org') {
    return SITE_VARIANTS.PRIVATE;
  }
  return SITE_VARIANTS.PUBLIC;
};

// Alternative: Use environment variable if set
export const getSiteVariantFromEnv = () => {
  const envVariant = process.env.REACT_APP_SITE_VARIANT;
  // console.log('Environment variant:', envVariant); // Removed debug log
  
  if (envVariant && Object.values(SITE_VARIANTS).includes(envVariant)) {
    // console.log('Using environment variant:', envVariant); // Removed debug log
    return envVariant; // Return the environment variant if valid
  }
  
  // Fall back to hostname detection ONLY if env var is not set or invalid
  const hostnameVariant = getSiteVariant();
  // console.log('Using hostname variant:', hostnameVariant); // Removed debug log
  return hostnameVariant;
};

// Get the current site configuration
export const getCurrentSiteConfig = () => {
  const variant = getSiteVariantFromEnv();
  return siteConfigs[variant];
};
