import React from 'react'; // Re-added React import
import styled from 'styled-components';
import { colorPalettes } from '../../styles/theme';

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[3]};
  border-radius: ${({ theme }) => theme.radii.sm};
  transition: all 0.2s ease;
  background-color: ${({ isSelected }) => 
    isSelected ? 'var(--primary-color)' : 'transparent'};
  color: ${({ isSelected }) => 
    isSelected ? 'var(--secondary-color)' : 'var(--primary-color)'};
  
  /* Mobile optimization - larger touch target */
  min-height: 44px;
  
  &:hover {
    background-color: ${({ isSelected }) => 
      isSelected ? 'var(--primary-color)' : 'var(--primary-hover)'};
  }
  
  /* Add touch feedback */
  &:active {
    background-color: ${({ isSelected }) => 
      isSelected ? 'var(--primary-active)' : 'var(--primary-hover)'};
    transform: scale(0.98);
    transition: all 0.1s ease;
  }
`;

const PalettePreview = styled.div`
  display: flex;
  width: 50px;
  height: 24px; /* Slightly taller for better visibility */
  border-radius: 4px; /* Slightly larger radius */
  overflow: hidden;
  margin-right: ${({ theme }) => theme.space[2]};
  border: 1px solid rgba(0, 0, 0, 0.1);
  
  /* Mobile optimization - slightly larger on small screens */
  ${({ theme }) => theme.mediaQueries.xs} {
    width: 60px;
    height: 28px;
  }
`;

const PreviewLeft = styled.div`
  width: 50%;
  height: 100%;
  background-color: ${({ color }) => color};
`;

const PreviewRight = styled.div`
  width: 50%;
  height: 100%;
  background-color: ${({ color }) => color};
`;

const PaletteName = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: inherit;
  text-transform: capitalize;
  font-weight: ${({ isSelected, theme }) => 
    isSelected ? theme.fontWeights.bold : theme.fontWeights.normal};
  
  /* Mobile optimization - slightly larger text on small screens */
  ${({ theme }) => theme.mediaQueries.xs} {
    font-size: ${({ theme }) => theme.fontSizes.md};
  }
`;

const PaletteOption = ({ paletteName, isSelected, onClick }) => {
  // Format palette name for display
  const displayName = paletteName.charAt(0).toUpperCase() + paletteName.slice(1);
  
  // Handle random palette preview
  if (paletteName === 'random') {
    return (
      <OptionContainer 
        onClick={onClick} 
        isSelected={isSelected}
        role="button"
        aria-pressed={isSelected}
      >
        <PalettePreview>
          <PreviewLeft style={{ background: 'linear-gradient(45deg, #ff0000, #00ff00, #0000ff)' }} />
          <PreviewRight style={{ background: 'linear-gradient(45deg, #ffffff, #f0f0f0, #e0e0e0)' }} />
        </PalettePreview>
        <PaletteName isSelected={isSelected}>{displayName}</PaletteName>
      </OptionContainer>
    );
  }
  
  // Get colors from palette
  const palette = colorPalettes[paletteName] || colorPalettes.classic;
  
  return (
    <OptionContainer 
      onClick={onClick} 
      isSelected={isSelected}
      role="button"
      aria-pressed={isSelected}
    >
      <PalettePreview>
        <PreviewLeft color={palette.primary} />
        <PreviewRight color={palette.secondary} />
      </PalettePreview>
      <PaletteName isSelected={isSelected}>{displayName}</PaletteName>
    </OptionContainer>
  );
};

export default PaletteOption;
