import React, { useState, useEffect } from 'react'; // Re-added React import
import styled from 'styled-components';
import Input from './Input';

const SearchForm = styled.form`
  width: 100%; /* Keep width 100% */
  /* max-width: 500px; Removed */
  /* margin: 0 auto; Removed */
  position: relative;
  flex-grow: 1; /* Added to allow expansion */

  /* Removed commented-out max-width constraints */
  ${({ theme }) => theme.mediaQueries.xs} {
    padding: 0 ${({ theme }) => theme.space[2]}; /* Keep mobile padding */
  }
   ${({ theme }) => theme.mediaQueries.md} {
     padding: 0; /* Reset padding for larger screens */
  }
`;

// Search snippets for random placeholders
const searchSnippets = [
  // Original Reading Rainbow snippets (excluding "Reading Rainbow")
  "Butterfly in the sky",
  "I can go twice as high",
  "I can go anywhere", 
  "Friends to know and ways to grow",
  "I can be anything",
  "Take a look its in a book",
  
  // New options
  "Find the music in the silence",
  "Paint with all the colors of the wind",
  "Unlock doors with curious keys",
  "Follow the echoes of starlight",
  "Plant a seed and watch it dream",
  "See the patterns hidden in plain sight",
  "Ride the currents of the air",
  "Listen to the stories stones tell",
  "Build bridges made of light",
  "Find the map within your heart",
  "Taste the sparkle of the dawn",
  "Weave threads of possibility",
  "Dance with your own shadow",
  "Hear the secrets rivers carry",
  "Climb mountains made of maybe",
  "Discover worlds inside a raindrop",
  "Let your imagination take flight",
  "Gather whispers from the leaves",
  "Find constellations in your teacup",
  "Grow roots deep and reach high",
  "Decode the language of the birds",
  "Find magic in the mundane",
  "Chase the horizon's gentle curve",
  "See the glow of inner knowing",
  "Ask the moon your quiet questions"
];

const SearchInput = ({ onSearch, placeholder, ...props }) => {
  const [randomPlaceholder, setRandomPlaceholder] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  
  // Set a random placeholder on mount
  useEffect(() => {
    setRandomPlaceholder(
      searchSnippets[
        Math.floor(Math.random() * searchSnippets.length)
      ]
    );
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (onSearch) {
      onSearch(searchTerm);
      setSearchTerm(""); // Clear the search input after submission
      
      // Set a new random placeholder
      setRandomPlaceholder(
        searchSnippets[
          Math.floor(Math.random() * searchSnippets.length)
        ]
      );
    }
  };
  
  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <SearchForm onSubmit={handleSubmit}>
      <Input
        name="searchInput"
        placeholder={placeholder || randomPlaceholder}
        value={searchTerm}
        onChange={handleChange}
        aria-label="Search"
        role="textbox"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
        {...props}
      />
    </SearchForm>
  );
};

export default SearchInput;
