import React from 'react'; // Removed memo import
import styled from 'styled-components';

// Import child components
import NavMenu from './NavMenu';
import SearchInput from '../Form/SearchInput';
import ColorPalette from '../ThemeSelector/ColorPalette';

// Removed Memoized component definitions

const FooterBar = styled.footer`
  height: 60px; /* Adjust height as needed */
  background-color: var(--primary-color);
  border-top: 1px solid var(--secondary-color);
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space out left and right groups */
  padding: 0 ${({ theme }) => theme.space[3]};
  z-index: ${({ theme }) => theme.zIndices.sticky};
  flex-shrink: 0; /* Prevent footer from shrinking */
  position: relative; /* Needed for potential absolute positioning of children like NavMenu popup */

  /* Mobile adjustments if necessary */
  ${({ theme }) => theme.mediaQueries.xs} {
    height: 50px; /* Example: smaller height on mobile */
    padding: 0 ${({ theme }) => theme.space[2]};
  }
`;

const LeftGroup = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space[3]}; /* Space between NavMenu icon and Search */
  flex-grow: 1; /* Allow this group to expand */
  /* Add a margin-right to prevent SearchInput touching ColorPalette if needed */
  margin-right: ${({ theme }) => theme.space[3]}; 
`;

const RightGroup = styled.div`
  display: flex;
  align-items: center;
`;

const Footer = ({
  // Removed unused theme prop
  links,
  onSearch,
  selectedPalette,
  onSelectPalette,
  isNavMenuOpen,
  toggleNavMenu,
  setIframeSrc,
}) => {
  return (
    <FooterBar>
      <LeftGroup>
        <NavMenu // Use original component
          links={links}
          isNavMenuOpen={isNavMenuOpen}
          toggleNavMenu={toggleNavMenu}
          setIframeSrc={setIframeSrc}
        />
        <SearchInput onSearch={onSearch} /> {/* Use original component */}
      </LeftGroup>
      <RightGroup>
        <ColorPalette // Use original component
          selectedPalette={selectedPalette}
          onSelectPalette={onSelectPalette}
        />
      </RightGroup>
    </FooterBar>
  );
};

export default Footer; // Removed memo export
