// Breakpoints for responsive design
export const breakpoints = {
  xs: '320px',   // Small phones
  sm: '480px',   // Larger phones
  md: '768px',   // Tablets
  lg: '1024px',  // Laptops/Desktops
  xl: '1280px',  // Large screens
  '2xl': '1536px', // Extra large screens
};

export const mediaQueries = {
  xs: `@media (min-width: ${breakpoints.xs})`,
  sm: `@media (min-width: ${breakpoints.sm})`,
  md: `@media (min-width: ${breakpoints.md})`,
  lg: `@media (min-width: ${breakpoints.lg})`,
  xl: `@media (min-width: ${breakpoints.xl})`,
  '2xl': `@media (min-width: ${breakpoints['2xl']})`,
};
