import { useEffect } from 'react';

/**
 * Custom hook to add a global keyboard listener that focuses the main search input
 * when the user starts typing alphanumeric or specific special characters outside
 * of an input field.
 *
 * @param {string} inputSelector - CSS selector for the search input element (e.g., 'input[name="searchInput"]').
 */
const useGlobalSearchFocus = (inputSelector = 'input[name="searchInput"]') => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      // Get the search input element
      const searchInput = document.querySelector(inputSelector);

      // Skip if no search input found
      if (!searchInput) return;

      // Skip if user is already typing in an input, textarea, or contentEditable element
      if (
        document.activeElement.tagName === 'INPUT' ||
        document.activeElement.tagName === 'TEXTAREA' ||
        document.activeElement.isContentEditable
      ) {
        return;
      }

      // Define allowed special characters (adjust as needed)
      const allowedSpecialChars = '`~!@#$%^&*()_+-=';

      // Skip for modifier keys or non-character keys
      if (
        event.ctrlKey || event.altKey || event.metaKey || event.shiftKey ||
        event.key.length > 1 // Skip special keys like Tab, Escape, F1-F12, etc.
      ) {
        return;
      }

      // Allow alphanumeric characters and the specified special characters
      if (!(
        /^[a-zA-Z0-9]$/.test(event.key) || // Alphanumeric
        allowedSpecialChars.includes(event.key) // Allowed special characters
      )) {
        return;
      }

      // Prevent default browser behavior/shortcuts for the triggering key
      event.preventDefault();

      // Focus the search input
      searchInput.focus();

      // Set the value to the pressed key (capturing the first keypress)
      // Note: This assumes the input is uncontrolled initially or handles this update correctly.
      // If it's a controlled component, the parent needs to manage the state update.
      // Dispatching the 'input' event helps sync with React's event system.
      searchInput.value = event.key;
      const inputEvent = new Event('input', { bubbles: true });
      searchInput.dispatchEvent(inputEvent);
    };

    // Add event listener
    window.addEventListener('keydown', handleKeyDown);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [inputSelector]); // Re-run effect if inputSelector changes
};

export default useGlobalSearchFocus;
