import React, { useState, useRef } from 'react'; // Re-added React import
import styled from 'styled-components';
import { colorPalettes } from '../../styles/theme';
import PaletteOption from './PaletteOption';
import FloatingPopup from '../Layout/FloatingPopup'; // Import FloatingPopup

const PaletteContainer = styled.div`
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* user-select: none; -> Can be applied globally or kept if needed */
`;

const PaletteToggle = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  
  /* Mobile optimization - larger touch target */
  width: 44px;
  height: 44px;
  
  /* Add touch feedback */
  &:active {
    transform: scale(0.95);
    transition: transform 0.1s ease;
  }
  
  ${({ theme }) => theme.mediaQueries.lg} {
    width: 48px;
    height: 48px;
  }
`;

// PaletteOptions styled component is removed

const PaletteEmoji = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.xl};
`;

const ColorPalette = ({ selectedPalette, onSelectPalette }) => {
  const [isOpen, setIsOpen] = useState(false);
  const triggerRef = useRef(null); // Use triggerRef convention
  
  const togglePalette = () => {
    setIsOpen(prev => !prev); // Use functional update for safety
  };

  // Click outside logic is now handled by FloatingPopup

  // Get all palette names
  const paletteNames = Object.keys(colorPalettes);
  
  // Add random option
  const allOptions = [...paletteNames, 'random'];

  return (
    <PaletteContainer>
      {/* Assign ref to the trigger */}
      <PaletteToggle 
        ref={triggerRef} 
        onClick={togglePalette} 
        aria-label="Toggle color palette"
        aria-haspopup="true"
        aria-expanded={isOpen}
      >
        <PaletteEmoji>🎨</PaletteEmoji>
      </PaletteToggle>
      {/* Use FloatingPopup */}
      <FloatingPopup
        isOpen={isOpen}
        onClose={togglePalette} // Pass toggle as onClose
        triggerRef={triggerRef}
        alignment="right"
      >
        {allOptions.map((paletteName) => (
          <PaletteOption
            key={paletteName}
            paletteName={paletteName}
            isSelected={selectedPalette === paletteName}
            onClick={() => {
              onSelectPalette(paletteName);
              setIsOpen(false);
            }}
          />
        ))}
      </FloatingPopup>
    </PaletteContainer>
  );
};

export default ColorPalette;
