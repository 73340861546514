import React, { useEffect, useRef } from 'react'; // Ensure useState is removed
import styled from 'styled-components';

// --- Constants ---
// Assuming footer height is relatively fixed, adjust if dynamic
const FOOTER_HEIGHT_APPROX = 60; 
// Default padding above the footer
const BOTTOM_PADDING = 16; // Corresponds roughly to theme.space[3]

// --- Styled Components ---
const PopupContainer = styled.div`
  display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
  position: fixed; /* Position relative to viewport */
  flex-direction: column;
  background-color: var(--secondary-color);
  border-radius: ${({ theme }) => theme.radii.md};
  padding: ${({ theme }) => theme.space[3]};
  box-shadow: ${({ theme }) => theme.shadows.lg};
  gap: ${({ theme }) => theme.space[2]};
  max-height: 70vh;
  overflow-y: auto;
  z-index: ${({ theme }) => theme.zIndices.modal};
  min-width: 200px;
  color: var(--primary-color); /* Default text color inside */

  /* Dynamic positioning styles */
  bottom: ${({ $styles }) => $styles.bottom || 'auto'};
  left: ${({ $styles }) => $styles.left || 'auto'};
  right: ${({ $styles }) => $styles.right || 'auto'};

  /* Mobile optimization */
  -webkit-overflow-scrolling: touch;
  ${({ theme }) => theme.mediaQueries.xs} {
    max-width: calc(100vw - ${({ theme }) => theme.space[4]}); /* Add some edge padding */
    min-width: 150px;
  }
`;

// --- Component ---
const FloatingPopup = ({
  isOpen,
  onClose,
  triggerRef, // Ref of the element that triggers the popup
  alignment = 'left', // 'left', 'right', 'center' (center needs more work)
  children,
}) => {
  const popupRef = useRef(null);
  // Removed unused popupStyles state: const [popupStyles, setPopupStyles] = useState({});
  const [calculatedStyles, setCalculatedStyles] = React.useState({}); // Use a different name to avoid confusion if needed later

  // --- Positioning Logic ---
  useEffect(() => {
    const calculatePosition = () => {
      if (!isOpen || !triggerRef?.current || !popupRef?.current) {
        // Reset styles if closed or refs not ready
        // Removed commented-out setPopupStyles({});
        return;
      }

      const triggerRect = triggerRef.current.getBoundingClientRect();
      const popupRect = popupRef.current.getBoundingClientRect(); // Get popup dimensions
      const viewportWidth = window.innerWidth;
      // const viewportHeight = window.innerHeight; // Removed unused variable

      const bottom = `${FOOTER_HEIGHT_APPROX + BOTTOM_PADDING}px`;
      let left = 'auto';
      let right = 'auto';

      if (alignment === 'left') {
        left = `${triggerRect.left}px`;
        // TODO: Add boundary check - if left + popupWidth > viewportWidth, adjust left
      } else if (alignment === 'right') {
        right = `${viewportWidth - triggerRect.right}px`;
        // TODO: Add boundary check - if right + popupWidth > viewportWidth, adjust right
      } else if (alignment === 'center') {
        // Center alignment is trickier, needs popup width
        const popupWidth = popupRect.width;
        left = `${triggerRect.left + triggerRect.width / 2 - popupWidth / 2}px`;
        // TODO: Add boundary checks for center
      }

      setCalculatedStyles({ bottom, left, right }); // Update the state
    };

    // Calculate initially and on resize
    calculatePosition(); 
    window.addEventListener('resize', calculatePosition);

    // Recalculate if isOpen changes (might need slight delay for popupRef dimensions)
    // Using a small timeout allows the popup to render before measuring
    let timeoutId;
    if (isOpen) {
       timeoutId = setTimeout(calculatePosition, 0);
    }

    return () => {
      window.removeEventListener('resize', calculatePosition);
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [isOpen, triggerRef, alignment]); // Rerun when these change

  // --- Click Outside Logic ---
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpen &&
        popupRef.current &&
        !popupRef.current.contains(event.target) &&
        triggerRef?.current &&
        !triggerRef.current.contains(event.target) // Also check trigger
      ) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [isOpen, onClose, triggerRef]); // Depend on isOpen, onClose, triggerRef

  return (
    // Use calculatedStyles for the styles prop
    <PopupContainer ref={popupRef} $isOpen={isOpen} $styles={calculatedStyles}>
      {children}
    </PopupContainer>
  );
};

export default FloatingPopup;
