// Color palettes definition
export const colorPalettes = {
  // Original palettes
  light: {
    primary: '#ffffff',
    secondary: '#333333',
    accent: '#3498db',
    success: '#2ecc71',
    warning: '#f39c12',
    error: '#e74c3c',
    info: '#3498db',
  },
  dark: {
    primary: '#333333',
    secondary: '#f5f5f5',
    accent: '#3498db',
    success: '#2ecc71',
    warning: '#f39c12',
    error: '#e74c3c',
    info: '#3498db',
  },
  black: {
    primary: '#000000',
    secondary: '#ffffff',
    accent: '#3498db',
    success: '#2ecc71',
    warning: '#f39c12',
    error: '#e74c3c',
    info: '#3498db',
  },
  midnight: {
    primary: '#2c2157',
    secondary: '#e6e6ff',
    accent: '#9c27b0',
    success: '#1e8449',
    warning: '#f39c12',
    error: '#c0392b',
    info: '#7e57c2',
  },
  forest: {
    primary: '#2d4a22',
    secondary: '#f0f7da',
    accent: '#3498db',
    success: '#27ae60',
    warning: '#f39c12',
    error: '#c0392b',
    info: '#2980b9',
  },
  ocean: {
    primary: '#1a3a5f',
    secondary: '#e6f2ff',
    accent: '#3498db',
    success: '#27ae60',
    warning: '#f39c12',
    error: '#c0392b',
    info: '#2980b9',
  },
  sunset: {
    primary: '#5e2b4a',
    secondary: '#ffe6f2',
    accent: '#3498db',
    success: '#27ae60',
    warning: '#f39c12',
    error: '#c0392b',
    info: '#2980b9',
  },
  desert: {
    primary: '#8b4513',
    secondary: '#f5e6c8',
    accent: '#3498db',
    success: '#27ae60',
    warning: '#f39c12',
    error: '#c0392b',
    info: '#2980b9',
  },
  lavender: {
    primary: '#9575cd',
    secondary: '#f3e5f5',
    accent: '#7986cb',
    success: '#27ae60',
    warning: '#f39c12',
    error: '#c0392b',
    info: '#b39ddb',
  },
  mint: {
    primary: '#2a6b5f',
    secondary: '#e6fff7',
    accent: '#3498db',
    success: '#27ae60',
    warning: '#f39c12',
    error: '#c0392b',
    info: '#2980b9',
  },
  cherry: {
    primary: '#d32f2f',
    secondary: '#ffebee',
    accent: '#ff5722',
    success: '#27ae60',
    warning: '#f39c12',
    error: '#c0392b',
    info: '#ff8a65',
  },
  slate: {
    primary: '#2c3e50',
    secondary: '#ecf0f1',
    accent: '#3498db',
    success: '#27ae60',
    warning: '#f39c12',
    error: '#c0392b',
    info: '#2980b9',
  },
  emerald: {
    primary: '#046307',
    secondary: '#e0ffe0',
    accent: '#3498db',
    success: '#27ae60',
    warning: '#f39c12',
    error: '#c0392b',
    info: '#2980b9',
  },
  amethyst: {
    primary: '#663399',
    secondary: '#f5e6ff',
    accent: '#3498db',
    success: '#27ae60',
    warning: '#f39c12',
    error: '#c0392b',
    info: '#2980b9',
  },
  
  // Nature-inspired palettes
  arctic: {
    primary: '#e0f7fa',
    secondary: '#01579b',
    accent: '#00bcd4',
    success: '#26a69a',
    warning: '#90a4ae',
    error: '#f44336',
    info: '#29b6f6',
  },
  tropical: {
    primary: '#1b5e20',
    secondary: '#fff8e1',
    accent: '#ff9800',
    success: '#4caf50',
    warning: '#ffeb3b',
    error: '#ff5722',
    info: '#00e676',
  },
  autumn: {
    primary: '#bf360c',
    secondary: '#fff3e0',
    accent: '#ff6d00',
    success: '#8d6e63',
    warning: '#ffd54f',
    error: '#d84315',
    info: '#a1887f',
  },
  spring: {
    primary: '#f1f8e9',
    secondary: '#33691e',
    accent: '#cddc39',
    success: '#8bc34a',
    warning: '#ffeb3b',
    error: '#ef9a9a',
    info: '#aed581',
  },
  savanna: {
    primary: '#8d6e63',
    secondary: '#fffde7',
    accent: '#ffc107',
    success: '#689f38',
    warning: '#ff8f00',
    error: '#d32f2f',
    info: '#afb42b',
  },
  coral: {
    primary: '#e64a19',
    secondary: '#e0f7fa',
    accent: '#00acc1',
    success: '#26a69a',
    warning: '#ffa726',
    error: '#d50000',
    info: '#4dd0e1',
  },
  
  // Time-based palettes
  dawn: {
    primary: '#ffcdd2',
    secondary: '#37474f',
    accent: '#ff8a80',
    success: '#81c784',
    warning: '#ffb74d',
    error: '#e57373',
    info: '#4fc3f7',
  },
  dusk: {
    primary: '#4527a0',
    secondary: '#f3e5f5',
    accent: '#7e57c2',
    success: '#66bb6a',
    warning: '#ffa726',
    error: '#ef5350',
    info: '#5c6bc0',
  },
  midnightBlue: {
    primary: '#0d47a1',
    secondary: '#e3f2fd',
    accent: '#448aff',
    success: '#00c853',
    warning: '#ffab00',
    error: '#d50000',
    info: '#2962ff',
  },
  daylight: {
    primary: '#ffeb3b',
    secondary: '#263238',
    accent: '#03a9f4',
    success: '#00e676',
    warning: '#ff9100',
    error: '#ff1744',
    info: '#40c4ff',
  },
  
  // Material-inspired palettes
  bronze: {
    primary: '#8d6e63',
    secondary: '#efebe9',
    accent: '#ff9800',
    success: '#43a047',
    warning: '#ffb300',
    error: '#c62828',
    info: '#6d4c41',
  },
  steel: {
    primary: '#455a64',
    secondary: '#eceff1',
    accent: '#29b6f6',
    success: '#66bb6a',
    warning: '#fdd835',
    error: '#ef5350',
    info: '#78909c',
  },
  gold: {
    primary: '#ff9800',
    secondary: '#212121',
    accent: '#ffc107',
    success: '#43a047',
    warning: '#ffb300',
    error: '#e53935',
    info: '#ffb74d',
  },
  ruby: {
    primary: '#9c0063',
    secondary: '#fff0f7',
    accent: '#e040fb',
    success: '#4caf50',
    warning: '#ff9800',
    error: '#d50000',
    info: '#f48fb1',
  },
  sapphire: {
    primary: '#1a237e',
    secondary: '#e8eaf6',
    accent: '#536dfe',
    success: '#43a047',
    warning: '#ffab00',
    error: '#d50000',
    info: '#3d5afe',
  },
  jade: {
    primary: '#004d40',
    secondary: '#e0f2f1',
    accent: '#1de9b6',
    success: '#00c853',
    warning: '#ffab00',
    error: '#dd2c00',
    info: '#00bfa5',
  },
  
  // Mood-based palettes
  calm: {
    primary: '#b2dfdb',
    secondary: '#004d40',
    accent: '#26a69a',
    success: '#4caf50',
    warning: '#fff176',
    error: '#ef9a9a',
    info: '#80cbc4',
  },
  energetic: {
    primary: '#ff5722',
    secondary: '#212121',
    accent: '#ffeb3b',
    success: '#76ff03',
    warning: '#ffc400',
    error: '#f44336',
    info: '#ff9800',
  },
  serene: {
    primary: '#bbdefb',
    secondary: '#1565c0',
    accent: '#64b5f6',
    success: '#66bb6a',
    warning: '#fff59d',
    error: '#ef9a9a',
    info: '#42a5f5',
  },
  contrast: {
    primary: '#000000',
    secondary: '#ffffff',
    accent: '#ff1744',
    success: '#00e676',
    warning: '#ffea00',
    error: '#d50000',
    info: '#2979ff',
  },
  
  // Cultural/Design-inspired palettes
  nordic: {
    primary: '#eceff1',
    secondary: '#263238',
    accent: '#90a4ae',
    success: '#78909c',
    warning: '#b0bec5',
    error: '#ef5350',
    info: '#607d8b',
  },
  mediterranean: {
    primary: '#03a9f4',
    secondary: '#ffffff',
    accent: '#0288d1',
    success: '#26a69a',
    warning: '#fdd835',
    error: '#f44336',
    info: '#4fc3f7',
  },
  cyberpunk: {
    primary: '#212121',
    secondary: '#ffffff',
    accent: '#00ffff',
    success: '#00ff00',
    warning: '#ffff00',
    error: '#ff00ff',
    info: '#00ffff',
  },
  retro: {
    primary: '#ffeb3b',
    secondary: '#3e2723',
    accent: '#ff5722',
    success: '#4caf50',
    warning: '#ff9800',
    error: '#f44336',
    info: '#2196f3',
  },
  monochrome: {
    primary: '#212121',
    secondary: '#fafafa',
    accent: '#757575',
    success: '#9e9e9e',
    warning: '#616161',
    error: '#424242',
    info: '#757575',
  },
  pastel: {
    primary: '#f8bbd0',
    secondary: '#4a148c',
    accent: '#b39ddb',
    success: '#c5e1a5',
    warning: '#ffe082',
    error: '#ffab91',
    info: '#81d4fa',
  },
};

// Removed unused adjustBrightness function
// Removed unused stateModifiers object
// Removed unused ensureContrast function
// Removed unused generateRandomColor function
