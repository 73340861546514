import React from 'react'; // Re-added React import
import styled from 'styled-components';

const StyledIFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  /* background-color removed, will be handled by srcDoc or loaded content */
`;

const IFrame = ({ src, theme }) => {
  // Generate srcDoc content only when src is null/undefined/empty
  const initialContent = !src 
    ? `<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Loading...</title>
  <style>
    body { 
      margin: 0; 
      padding: 0; 
      height: 100vh; 
      background-color: ${theme?.colors?.secondary || '#cccccc'}; /* Use theme secondary color or fallback */
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${theme?.colors?.primary || '#333333'}; /* Use theme primary color or fallback */
      font-family: sans-serif;
      font-size: 1.2em;
    }
  </style>
</head>
<body>
  <!-- Optional: Add a loading message or spinner here -->
</body>
</html>`
    : undefined; // Set to undefined when src is present

  return (
    <StyledIFrame 
      src={initialContent ? undefined : src} // Only set src if not using srcDoc
      srcDoc={initialContent} // Set srcDoc when src is empty
      title="Content Frame" // Add title for accessibility
    />
  );
};

export default IFrame;
