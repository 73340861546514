import React, { useRef } from 'react'; // Re-added React import
import styled from 'styled-components';
import FloatingPopup from './FloatingPopup'; // Import the new component

const NavContainer = styled.div`
  position: relative; /* Needed for absolute positioning of popup */
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  display: flex;
  align-items: center;
`;

const MenuIcon = styled.img`
  cursor: pointer; /* Make icon clickable */
  width: 32px;
  height: 32px;
  display: block;
  /* margin-right removed as options are now in popup */

  /* Add touch feedback */
  &:active {
    transform: scale(0.95);
    transition: transform 0.1s ease;
  }
`; // Close MenuIcon definition correctly

// NavPopup styled component is removed, replaced by FloatingPopup

// Modified NavLink (now a button)
const NavLinkItem = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[3]};
  border-radius: ${({ theme }) => theme.radii.sm};
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  text-decoration: none;
  color: var(--primary-color); /* Text color inside popup */
  background-color: transparent; /* Ensure button background is clear */
  border: none; /* Remove default button border */
  text-align: left; /* Align text left */
  width: 100%; /* Take full width */
  font-family: ${({ theme }) => theme.fontFamilies.mono};
  font-weight: ${({ theme }) => theme.fontWeights.regular}; /* Adjust weight if needed */
  font-size: ${({ theme }) => theme.fontSizes.sm}; /* Adjust size if needed */
  
  &:hover {
    background-color: var(--primary-color); /* Hover background */
    color: var(--secondary-color); /* Hover text color */
  }

  /* Add touch feedback */
  &:active {
    opacity: 0.9;
    transform: scale(0.99); /* Subtle scale */
  }
`;


const NavMenu = ({ links, isNavMenuOpen, toggleNavMenu, setIframeSrc }) => {
  const triggerRef = useRef(null); // Rename ref for clarity

  const handleLinkClick = (url) => {
    setIframeSrc(url);
    toggleNavMenu(); // Close menu after selection
  };

  return (
    <NavContainer>
      {/* Assign ref to the actual trigger element */}
      <MenuIcon 
        ref={triggerRef} 
        src="/assets/favicon-32x32.png" 
        alt="Menu" 
        onClick={toggleNavMenu} 
        aria-haspopup="true"
        aria-expanded={isNavMenuOpen}
      />
      {/* Use FloatingPopup */}
      <FloatingPopup
        isOpen={isNavMenuOpen}
        onClose={toggleNavMenu} // Pass the toggle function as onClose
        triggerRef={triggerRef}
        alignment="left"
      >
        {links.map((link, index) => (
          <NavLinkItem 
            key={index} 
            onClick={() => handleLinkClick(link.url)}
            // target and rel are not needed as we use setIframeSrc
          >
            {/* Future: Add favicon here */}
            {link.label}
          </NavLinkItem>
        ))}
      </FloatingPopup>
    </NavContainer>
  );
};

export default NavMenu;
