import React from 'react'; // Re-added React import
import styled from 'styled-components';

const StyledInput = styled.input`
  font-family: ${({ theme }) => theme.fontFamilies.mono};
  width: 100%;
  padding: ${({ theme }) => theme.space[2]}; /* Reduced padding */
  border: 2px solid var(--primary-color);
  background-color: var(--secondary-color);
  color: var(--primary-color);
  outline: none;
  transition: all 0.2s ease-in-out;
  
  /* Remove default browser focus styles */
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  
  /* Mobile optimization - larger text on mobile for better readability */
  ${({ theme }) => theme.mediaQueries.xs} {
    font-size: ${({ theme }) => theme.fontSizes.lg};
    padding: ${({ theme }) => theme.space[3]}; /* Reduced mobile padding */
    border-radius: 8px; /* Slightly rounded corners for better mobile UX */
  }
  
  ${({ theme }) => theme.mediaQueries.md} {
    font-size: ${({ theme }) => theme.fontSizes.md};
    padding: ${({ theme }) => theme.space[2]}; /* Reduced padding */
  }
  
  &:focus {
    outline: none;
    border-color: var(--accent-color);
    box-shadow: 0 0 0 2px rgba(var(--accent-color-rgb), 0.3);
  }
  
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  &::placeholder {
    color: var(--primary-color);
    opacity: 0.5;
  }
  
  /* Mobile optimization - better handling of autofill */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--primary-color);
    transition: background-color 5000s ease-in-out 0s;
  }
  
  /* Mobile optimization - prevent zoom on focus in iOS */
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 16px; /* Minimum font size to prevent zoom on iOS */
  }
`;

const Input = ({ ...props }) => {
  return <StyledInput {...props} />;
};

export default Input;
